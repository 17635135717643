import React from 'react';
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import EventEmitter from 'events'
import {alertConstants} from "../../constants"




export default class AlertPresentationnal extends React.Component
{
    constructor(props)
    {
        super(props);
        // this.state =
        //     {
        //         // show : props.show
        //         showOpenTrade : false,
        //         showCloseTrade : false
        //     }

        // this.alert = this.alert.bind(this)
    }


    static alert(type, onCloseCallback)
    {
        Alert.info(type === alertConstants.OPEN_ALERT ? 'Trade ouvert !' : 'Trade fermé !',
            {
                position: 'bottom-right',
                effect: 'slide',
                html: false,
                beep: require('./sounds/unsure.mp3'),
                timeout: 2000,
                onClose: onCloseCallback
                // onClose: this.closeAlert(type)
            });
    }

    componentWillReceiveProps(nextProps, nextContext)
    {
        const {type, onCloseCallback} = nextProps

        // this.alert(type, onCloseCallback)

    }

    //
    // componentWillUnmount()
    // {
    //     this.setState({showOpenTrade : false , showCloseTrade : false })
    // }
    //
    // closeAlert(type)
    // {
    //     if (type === "open")
    //         return () => this.setState(prevState => ({...prevState, showOpenTrade: false }))
    //     else if (type === "close")
    //         return () =>  this.setState(prevState => ({...prevState, showCloseTrade: false }))
    // }
    render()
    {

        return (
            <div >
                <Alert stack={{limit: 5}} />
            </div>
        )


        // return (this.state.show ? <AlertComp /> : null)
    }

}



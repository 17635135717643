import { websocketConstants } from '../../constants';
import round from 'round-to';


// cf tradeContainer comments for more precision about the structure of data
function indicatorReducer (state = [], action)
{
    switch(action.type)
    {

        case websocketConstants.BALANCE_UPDATE:

            let found = state.find(function(element)
            {
                return element.code === action.payload.code;
            })

            if (found)
            {
                return state.map(function(x)
                {
                    let tmpBuy = action.payload.code === x.code ? action.payload.balanceBuy : x.balanceBuy;
                    let tmpSell = action.payload.code === x.code ? action.payload.balanceSell : x.balanceSell;
                    x.balanceBuy = tmpBuy;
                    x.balanceSell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: action.payload.balanceBuy, balanceSell: action.payload.balanceSell, buy: null, sell: null, strengthBuy: null, strengthSell: null}, ...state];
            }  
        
        case websocketConstants.ACCURATE_UPDATE:

            let foundAccurate = state.find(function(element) {
                return element.code === action.payload.code;
            });

            if (foundAccurate)
            {
                return state.map(function(x) {
                    let tmpBuy = action.payload.code === x.code ? round(parseFloat(String(action.payload.buy).replace(',', '.')) * 100, 2) : x.buy;
                    let tmpSell = action.payload.code === x.code ? round(parseFloat(String(action.payload.sell).replace(',', '.')) * 100, 2) : x.sell;
                    x.buy = tmpBuy;
                    x.sell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: null, balanceSell: null, buy: round(parseFloat(String(action.payload.buy).replace(',', '.')) * 100, 2), sell: round(parseFloat(String(action.payload.sell).replace(',', '.')) * 100, 2), strengthBuy: null, strengthSell: null}, ...state];
            } 

        case websocketConstants.STRENGTH_UPDATE:
            let foundstrength = state.find(function(element)
            {
                return element.code === action.payload.code;
            });

            if (foundstrength) {
                return state.map(function(x) {
                    let tmpBuy = action.payload.code === x.code ? round(parseFloat(String(action.payload.strengthBuy).replace(',', '.')), 1) : x.strengthBuy;
                    let tmpSell = action.payload.code === x.code ? round(parseFloat(String(action.payload.strengthSell).replace(',', '.')), 1) : x.strengthSell;
                    x.strengthBuy = tmpBuy;
                    x.strengthSell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: null, balanceSell: null, buy: null, sell: null, strengthBuy: round(parseFloat(String(action.payload.strengthBuy).replace(',', '.')), 1), strengthSell: round(parseFloat(String(action.payload.strengthSell).replace(',', '.')), 1)}, ...state];
            } 

        default:
            return state;
    }
}

export default indicatorReducer;

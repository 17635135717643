import axios from 'axios';
import {IAConstants, IA_STATUS_URL, IA_REVERSE_STATUS_URL,} from '../../constants';





//TODO Bugg : #1 Request en pending #2 process.env non acessible
export const getIAStatusFromServer = async () =>
{
    const request = await axios.get(IA_STATUS_URL,{withCredentials: true})
    return{
        type: IAConstants.GET_IA_STATUS_FROM_SERVER,
        payload: request
    }
}


export const getIAReverseStatusFromSever = async () =>
{
    const request = await axios.get(IA_REVERSE_STATUS_URL,{withCredentials: true})

    return{
        type: IAConstants.GET_IA_REVERSE_STATUS_FROM_SERVER,
        payload: request
    }
}



export const changeIAStatus = (iaStatus) =>
{
    // Only changes the local save of ia. The request is made before that.
    return {
        type: IAConstants.CHANGE_STATUS,
        payload: iaStatus
    }
}


export const changeIAReverseStatus = (iaStatus) =>
{
    // Only changes the local save of reverse ia. The request is made before that.
    return {
        type: IAConstants.CHANGE_REVERSE_STATUS,
        payload: iaStatus
    }
}

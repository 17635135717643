import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuDrawer from './common/menuDrawer';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import {
    IA_REVERSE_STATUS_URL, IA_REVERSE_URL,
    IA_SHUTDOWN_URL,
    IA_SHUTDOWN_URL_LOCAL,
    IA_START_URL,
    IA_START_URL_LOCAL
} from '../constants';
import { getIAStatusFromServer, changeIAStatus, changeIAReverseStatus, getIAReverseStatusFromSever} from '../components/IA/IAAction';

import IAShutdownButton from '../components/IA/iaShutDown/IAShutdownButton';
import IAReverseButton from "../components/IA/iaReverse/IAReverseButton"

const styles = {
    grid: {
        margin: 50,
        width: "auto"
    },
    title: {
        fontSize: 25,
        fontVariant: "small-caps"
    }
};

class PrivateLayout extends Component
{


    // Cela ne doit pas etre ainsi, mais plutot thunkified !
    handleShutdownButton(event)
    {
        event.preventDefault()
        this.props.ia.iaStatus
            ?
            axios.get(IA_SHUTDOWN_URL,{

                withCredentials: true
            }) :
            axios.get(IA_START_URL,
                {
                    withCredentials: true
                })
        this.props.changeIAStatus(!this.props.ia.iaStatus)
    }

    handleReverseButton(event)
    {
        event.preventDefault()

        axios.get(IA_REVERSE_URL,
            {
                withCredentials: true
            })

        this.props.changeIAReverseStatus(!this.props.ia.iaReverseStatus)


    }


    componentDidMount()
    {

        this.props.getIAStatusFromServer()
        this.props.getIAReverseStatusFromSever()

    }

    render()
    {


        const Component = this.props.component

        const {
            classes,
            route,
            title,
            avatar,
            color
        } = this.props


        return (
            <div>
                <MenuDrawer />
                <Grid container spacing={8} direction="column" justify="space-around" className={classes.grid}>
                    <Grid item>
                        <Card>
                            <CardHeader
                                classes={{
                                    title: classes.title,
                                }}
                                avatar={
                                    <Avatar aria-label={title} style={{backgroundColor: color}}>
                                        {avatar}
                                    </Avatar>
                                }
                                action={
                                    <div>

                                        <IAShutdownButton
                                            received_info={this.props.ia.known_status}
                                            active={this.props.ia.iaStatus}
                                            onclick={this.handleShutdownButton.bind(this)} />

                                        <IAReverseButton
                                            received_info={this.props.ia.known_reverse_status}
                                            active={this.props.ia.iaReverseStatus}
                                            onclick={this.handleReverseButton.bind(this)} />

                                    </div>
                                }
                                title={title}
                            />
                        </Card>
                    </Grid>
                    <Grid item>
                        <Component route={route} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps({ ia })
{
    return { ia };
};

const mapDispatchToProps = (dispatch) =>
{
    return bindActionCreators({ getIAStatusFromServer, changeIAStatus, getIAReverseStatusFromSever, changeIAReverseStatus }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrivateLayout));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Eject from '@material-ui/icons/Eject';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import {ia_shutdown_button_labels, used_language, ia_waiting, ia_reverse_labels} from '../../../langues';
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

const styles =
    {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        buttonActive:
            {
                marginTop: 10,
                marginRight: 10,
                backgroundColor : "grey",
                textColor: "white"
            },
        buttonInactive:
            {
                marginTop: 10,
                marginRight: 10,
                backgroundColor : "white",
                textColor: "black"
            }
    };

const theme = createMuiTheme(
    {
        palette: {
            pr: {
                light: '#4dabf5',
                main: '#2980b9',
                dark: '#0276aa',
                contrastText: '#fff',
            },
            sec: red
        },
        typography: {
            useNextVariants: true
        }
    });

IAReverseButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onclick: PropTypes.func.isRequired
};

function IAReverseButton(props)
{
    const { classes, active, received_info, onclick } = props;
    const { isDemo } = props.user

    if (!isDemo)
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    {received_info === true ?
                        <Button variant="contained"  onClick={onclick} className={active? classes.buttonActive : classes.buttonInactive} >
                            {active ? <Eject /> : <PlayArrow /> }
                            {active ?  ia_reverse_labels[used_language][0]  : ia_reverse_labels[used_language][1]}
                        </Button>
                        :
                        <Button variant="contained" className={classes.button} disabled>
                            <PlayArrow />
                            {ia_waiting[used_language]}
                        </Button>
                    }
                </MuiThemeProvider>
            </div>
        )
    else
        return <div/>
}

function mapStateToProps({ user })
{
    return { user };
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(IAReverseButton)))
// export default withStyles(styles)(IAShutdownButton);

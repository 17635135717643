import { IAConstants } from '../../constants';


function iaReducer (state = {known_status: false}, action)
{
    switch(action.type)
    {
        
        case IAConstants.GET_IA_STATUS_FROM_SERVER:
            return {
                ...state,
                known_status: true, 
                iaStatus: action.payload.data[0].iaStatut
            }
            
        case IAConstants.CHANGE_STATUS:
            return {
                ...state,
                iaStatus: action.payload
            }


        case IAConstants.GET_IA_REVERSE_STATUS_FROM_SERVER:
            return {
                ...state,
                known_reverse_status: true,
                iaReverseStatus: action.payload.data[0].reverseStatus
        }


        case IAConstants.CHANGE_REVERSE_STATUS:
            return {
                ...state,
                iaReverseStatus: action.payload
            }

        default:
            return state

    }
}

export default iaReducer;

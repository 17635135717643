import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { markets } from '../markets/marketAction';
import { dev_trade, trade_ticks_and_prices } from '../../constants';
import { trade_side_label, used_language, total_balance_label, no_trade_label } from '../../langues';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


const styles = theme => ({
    root: {
        flexGrow: 1
    },
    control: {
        padding: theme.spacing.unit * 2
    },
    title: {
        fontSize: 20,
        fontWeigth: 'bold'
    },
    list: {
        width: '100%'
    },
    profit: {
        color: lightGreen[500]
    },
    loss: {
        color: red[500]
    },
    h3: {
        fontSize: 22,
        fontWeight: 'normal',
        margin: 0
    },
    h4: {
        fontSize: 15,
        color: grey[500],
        margin: 0,
        fontWeight: 'normal',
        fontVariant: 'small-caps'
    }
});

class OneStat extends Component
{
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    componentDidMount()
    {
        this.props.update();
    }

    render() {const { classes, stats } = this.props;
        let profit = 0
        if (stats !== undefined) {
            for (var i = 0; i < stats.length; i++) {
                profit += stats[i][dev_trade.PROFIT];
            }
        }

        return (
            <Grid container justify="flex-end" alignItems="flex-end" direction="column">
                <h3 className={[classes.h3, profit >= 0 ? classes.profit : classes.loss].join(" ")} >{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(profit)}</h3>
                <h4 className={classes.h4} >{ total_balance_label[used_language] }</h4>
                {
                    stats === undefined || stats.length === 0 ?
                    <Grid container item direction='row' justify="flex-start" alignItems="center">
                        <List className={classes.list} >
                            <ListItem button divider>
                                <ListItemText primary={no_trade_label[used_language]} />
                            </ListItem>
                        </List>
                    </Grid>
                    :
                    stats.map(stat => {
                        const market = markets.find(x => x.short === stat[dev_trade.MARKET]);
                        return (
                            <Grid container key={stat.timestamp1} item direction='row' justify="flex-start" alignItems="center">
                                <List className={classes.list} >
                                    <ListItem button divider>
                                        <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                                            {market.sigle}
                                        </Avatar>
                                        <ListItemText primary={trade_side_label[used_language][stat[dev_trade.SIDE]]} secondary={stat.date1} />
                                        <ListItemSecondaryAction className={stat[dev_trade.PROFIT] >= 0 ? classes.profit : classes.loss}>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: trade_ticks_and_prices[stat[dev_trade.MARKET]].currency }).format(stat[dev_trade.PROFIT])}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        )})}
            </Grid>
        );
    }
}

export default withStyles(styles)(OneStat);

import React, { Component } from 'react'
import Grid from "@material-ui/core/Grid"


export default class StatusIndicator extends React.Component
{
    constructor(props)
    {
        super(props)
    }


    render()
    {
        const {socketState} = this.props

        switch(socketState)
        {
            case 0: return (<status-indicator  negative={true}    pulse={true} />);
            case 1: return (<status-indicator  intermediary={true}    pulse={true} />);
            case 2: return (<status-indicator  positive={true}    pulse={true} />);
            default : return(<status-indicator pulse={true}/>)

        }


    }

}

import { getNewTrade, updateOneTrade, closeTrade } from '../trades/tradeAction'
import { updateMarketCard } from '../indicators/indicatorAction'
import { addNotification } from '../notifications/notificationAction'
import {jwtConstants, login_route, loginConstants, websocketConstants, LOGIN_URL,notificationConstants,} from "../../constants"
import history from "../../routes/history"
import axios from 'axios'
import * as io from "socket.io-client"
import {closeAlert, openAlert} from "../Alerts/AlertAction"



export const login =  (username, password, callback_success, callback_error) => {

    return  async function(dispatch)
    {

        await axios
            .post(LOGIN_URL,
                {
                    username: username,
                    password: password
                },{withCredentials: true})
            .then((response) =>
            {
                const {jwtToken} = response.data
                dispatch(addJwtToStore(jwtToken))

                const login_success = response.data.login_success
                const {idUser, isDemo} = response.data

                login_success ? callback_success() : callback_error()
                const loginData = {login_success, idUser, isDemo};

                dispatch(requestLogin(loginData))



            })
            .catch((error) =>
            {
                callback_error()
                const loginData = {login_success: false }
                dispatch(requestLogin(loginData))
            })



    }

}

//Redux-thunk
export function connectWebSocket(url)
{
    // Use of redux-thunk to return a function that dispatches the right actions.
    return async (dispatch,getState) =>
    {
        //TODO: Sécuriser la websocket

        dispatch(requestWebSocketConnection())

        const socket = await io.connect(url,
            {
                autoConnect: true,
                reconnection: true,
                reconnectionDelay: 500,
            })

        socket.on('connect', () =>
        {
            // console.log('Websocket connected with the server');
            dispatch(webSocketConnectionSuccess())
            socket.emit('handshake',{'message' :'hello'})
        })

        // socket.on(websocketConstants.HANDSHAKE_RECEIVED, (data) =>
        // {
        //     dispatch(webSocketConnectionSuccess())
        // })




        socket.on(websocketConstants.TRADE_OPENED, (data) =>
        {
            dispatch(getNewTrade(data))

            if(data.isCompletelyOpened)
            {
                // En attendant partially
                dispatch(addNotification(notificationConstants.NEW_TRADE, data))
                dispatch(openAlert(data))
            }

        })
        socket.on(websocketConstants.TRADE_CLOSED, (data) =>
        {
            dispatch(closeTrade(data))

            // TRICK
            if(data.isCompletelyClosed)
            {
                // En attendant partially
                dispatch(addNotification(notificationConstants.CLOSED_TRADE, data))
                dispatch(closeAlert(data))
            }


        })


        socket.on(websocketConstants.PRICE_UPDATE, (data) =>
        {
            dispatch(updateOneTrade(data));

        })

        // socket.on(websocketConstants.ACCURATE_UPDATE, (data) => {
        //     dispatch(updateMarketCard(data));
        // })
        // socket.on(websocketConstants.STRENGTH_UPDATE, (data) =>
        // {
        //     dispatch(updateMarketCard(data));
        // })
        // socket.on(websocketConstants.BALANCE_UPDATE, (data) =>
        // {
        //     dispatch(updateMarketCard(data))
        //
        // })



        socket.on('disconnect',  (e) =>
        {
            // console.log('Websocket closed ... ')

            dispatch(webSocketDisconnected())
            //TODO boucler ou pas sur la connection de websocket
            setTimeout(() =>
            {
                // RTODO Attention, c'est ici la connection
                connectWebSocket(url)
            },1000)
        })

        // socket.on('reconnect_failed', (error) => dispatch(webSocketConnectionFailure()))
        // socket.on('reconnect_error', (error) => dispatch(webSocketConnectionFailure()))
        socket.on('reconnecting' ,(e) =>
        {
            // console.log('Websocket reconnecting ... \n')
            dispatch(requestWebSocketConnection())

        })

        socket.on('error', (e) => {console.log('Error with the Websocket ...'); console.log(e);})

        dispatch(addSocketToStore(socket));

    }
}

export function requestLogin(loginData)
{
    return {
        type: loginConstants.LOGIN_REQUEST,
        payload: loginData
    }
}
export function logout()
{
    history.push(login_route)
    return {
        type: loginConstants.LOGOUT
    }
}

export function addJwtToStore(jwt)
{
    return {
        type: jwtConstants.AJOUT_JWT,
        payload: jwt
    }
}
export function addSocketToStore(socket)
{
    return {
        type: websocketConstants.SOCKET_ADD,
        payload: socket
    }
}
// Faudra bien utiliser cela et les faire reduire en user.socketState equivalent
export function requestWebSocketConnection()
{
    // 1
    return {
        type: websocketConstants.SOCKET_REQUEST
    }
}
export function webSocketConnectionSuccess()
{
    // 2
    return {
        type: websocketConstants.SOCKET_SUCCESS
    }
}
export function webSocketConnectionFailure()
{
    // 3
    return {
        type: websocketConstants.SOCKET_FAILURE
    }
}
export function webSocketDisconnected()
{
    return {
        type: websocketConstants.SOCKET_DISCONNECTED
    }
}

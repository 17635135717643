import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import BarChart from '@material-ui/icons/BarChart';
import Avatar from '@material-ui/core/Avatar';
import { Grid , GridList} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {HEADERS, indicator_headers, trade_headers, used_language} from '../../langues';
import {MarketsAllowed, TRADING_VIEW_URL} from "../../constants"
import {markets} from "../markets/marketAction"

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    card: {
        width: 300,
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardContent: {
        paddingTop:8,
        paddingBottom:0
    },
    alignCenter: {
        textAlign: 'center'
    },
    cardItem: {
        minWidth: 40,
        textAlign: 'center'
    },
    cardItemTitle:
        {
            textAlign: 'center',
            fontSize: 12,
            fontVariant: 'small-caps',
        },
    marginLeft: {
        marginLeft:15
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4CAF50',
            main: '#4CAF50',
            dark: '#388E3C',
            contrastText: '#fff',
        },
        sec: red
    },
    typography: {
        useNextVariants: true
    }
});
const cardContent = () => {
    {/*<CardContent className={classes.cardContent}>*/}
    {/*<Grid*/}
    {/*container*/}
    {/*direction="column"*/}
    {/*justify="space-around"*/}
    {/*alignItems="stretch"*/}
    {/*spacing={16}>*/}
    {/*<Grid item>*/}
    {/*<Grid container direction="row" justify="space-between"*/}
    {/*alignItems="center">*/}

    {/*<Grid item className={classes.cardItemTitle}>*/}
    {/*{indicator_headers[used_language][HEADERS.STRENGTH]}*/}
    {/*</Grid>*/}
    {/*<Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>*/}
    {/*{indicator_headers[used_language][HEADERS.ACCURATE]}*/}
    {/*</Grid>*/}
    {/*<Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>*/}
    {/*{indicator_headers[used_language][HEADERS.BALANCE]}*/}
    {/*</Grid>*/}
    {/*</Grid>*/}

    {/*</Grid>*/}

    {/*<Grid item>*/}
    {/*<Grid container direction="row" justify="space-between"*/}
    {/*alignItems="center">*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].strengthBuy > 0 ? indicators.filter(element => element.code === "FDAX")[0].strengthBuy : "-"}*/}
    {/*</Grid>*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].buy > 0 ? indicators.filter(element => element.code === "FDAX")[0].buy + '%' : "-"}*/}

    {/*</Grid>*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].balanceBuy > 0 ? indicators.filter(element => element.code === "FDAX")[0].balanceBuy : "-"}*/}
    {/*</Grid>*/}

    {/*</Grid>*/}

    {/*</Grid>*/}

    {/*<Grid item>*/}
    {/*<Grid container direction="row" justify="space-between"*/}
    {/*alignItems="center">*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{*/}
    {/*indicators.find(element => element.code === "FDAX") &&*/}
    {/*indicators.filter(element => element.code === "FDAX")[0].strengthSell > 0*/}
    {/*? indicators.filter(element => element.code === "FDAX")[0].strengthSell*/}
    {/*: "-"*/}
    {/*}*/}
    {/*</Grid>*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].sell > 0 ? indicators.filter(element => element.code === "FDAX")[0].sell + '%' : "-"}*/}
    {/*</Grid>*/}
    {/*<Grid item className={classes.cardItem}>*/}
    {/*{indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].balanceSell > 0 ? indicators.filter(element => element.code === "FDAX")[0].balanceSell : "-"}*/}
    {/*</Grid>*/}

    {/*</Grid>*/}
    {/*</Grid>*/}

    {/*</Grid>*/}


    {/*</CardContent>*/}
}
//TODO : Change to React.Component
class IndicatorPresentationnal extends React.Component
{
    constructor(props)
    {
        super(props)
        this.openWindowChart = this.openWindowChart.bind(this)

        this.state = { chartOpen : false, charts : [] }



    }

    openWindowChart(link)
    {

        // RTODO May not work
        window.open(TRADING_VIEW_URL, "Robankhood : Ana ", "width=1200,height=640")

    }
    render()
    {
        const {classes, indicators} = this.props;
        // Le market passé en props fout un peu le bordel parce qu'il est uniquement axé FDAX
        // alert(JSON.stringify(market))
        const listCharts = this.state["charts"]
        return (
            <div>
                <MuiThemeProvider theme={theme}>

                    <Grid alignContent={"flex-start"} container spacing={8}>
                            {
                                MarketsAllowed.map((marketName, index) =>
                                {

                                    const market = markets.find(x => x.short === marketName );

                                    return (
                                        <div key={index}>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    className={classes.cardHeader}
                                                    style={{"marginBottom" : "15px"}}
                                                    avatar={
                                                        <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                                                            {market.sigle}
                                                        </Avatar>
                                                    }
                                                    action={
                                                        <IconButton disabled={true} style={{color: 'black'}} onClick={(link) => this.openWindowChart(link)}>
                                                            <BarChart/>
                                                        </IconButton>
                                                    }
                                                    title={marketName}
                                                />
                                            </Card>

                                        </div>

                                    )
                                }

                            )}
                    </Grid>
                </MuiThemeProvider>
            </div>
        );
    }

}

function mapStateToProps({ indicators })
{
    return { indicators };
}

export default connect(mapStateToProps)(withStyles(styles)(IndicatorPresentationnal));


